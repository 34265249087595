export default {
  methods: {
    async updateGlobalWalletDetails(){
      let wallet_details = await this.$store.dispatch('getWalletDetails')
      this.$store.commit('SET_GLOBAL_WALLET_DETAILS', wallet_details.data)
    },
    async updateGlobalWalletOverview(){
      let wallet_overview = await this.$store.dispatch('getUserWalletOverview')
      this.$store.commit('SET_GLOBAL_WALLET_OVERVIEW', wallet_overview.data)
    },
    async updateGlobalUserDetails(){
      let user_data = await this.$store.dispatch('getUserDetails')
      this.$store.commit('SET_GLOBAL_USER_DATA', user_data.data)
    },
    async updateUserSubscription(){
      let subscription = await this.$store.dispatch('getListOfWalletSubscriptions', ["ACTIVE"])
      if(subscription.status === 200 && subscription.data.results.length === 0){
        subscription = await this.$store.dispatch('getListOfWalletSubscriptions', ["SUSPENDED"])
      }
      let plan_details = subscription.data.results[0].subscription_plan.plan_details
      const sortSubscriptionArr = ["n_wallets", "n_members", "n_detailed_cards", "n_guests", "traditional_client_support", "invoices_export_after_twelve_months", "free_personalized_card", "dedicated_whatsapp", "invoices_bulk_export", "api_integration"]
      let sort = plan_details.sort(function (a, b) {
        return sortSubscriptionArr.indexOf(a.name) - sortSubscriptionArr.indexOf(b.name)
      })
      plan_details = sort
      this.$store.commit('SET_GLOBAL_SUBSCRIPTION', subscription.data.results[0])
    }
  }
}
