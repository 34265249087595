
import { mapGetters } from "vuex"
export default {
  data(){
    return {
      routes: null
    }
  },
  mounted(){
    let routes = this.getRoutes
    let permission_id = 0
    if (this.userPermissions) {
      permission_id = this.userPermissions.id
    }
    let allowed_routes = []
      if(permission_id === 0){
        allowed_routes = routes
      } else {
        routes.forEach(v => {
        if(v.permissions >= permission_id){
          allowed_routes.push(v)
        }
      })
    }
    this.routes = allowed_routes
  },
  computed: {
    ...mapGetters(['getRoutes', 'userPermissions'])
  },
  methods: {
    clearError(){
      this.$store.commit('SET_ALERT', null)
    }
  }
}
