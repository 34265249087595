import { getUserFromCookie } from '@/helpers'
export default async function ({ app, route, redirect, req, store }){
  if (process.server) {
    let user = getUserFromCookie(req)
    if(!user && !route.params.wallet_guest){
      if(route.fullPath.includes("subscription")){
        store.commit("SET_PAGE_REDIRECT", "subscription")
        app.i18n.locale === "pt" ? redirect("/login?page=subscription") : redirect(`/${app.i18n.locale}/login?page=subscription`)
      } else {
        app.i18n.locale === "pt" ? redirect("/login") : redirect(`/${app.i18n.locale}/login`)
      }
    } else {
      if (req && req.headers.cookie) {
        let permission = req.headers.cookie.split(';').find(c => c.trim().startsWith('ZGCmCKTJLQpGtmUD'))
        if(permission){
          permission.replace('ZGCmCKTJLQpGtmUD=', '')
          if(permission != 0){
            if(route.path.includes('/subscription')){
              app.i18n.locale === "pt" ? redirect("/home") : redirect(`/${app.i18n.locale}/home`)
            } else if(route.path.includes('/wallet/settings')){
              app.i18n.locale === "pt" ? redirect("/wallet") : redirect(`/${app.i18n.locale}/wallet`)
            }
          }
        }
        let wallet_id = req.headers.cookie.split(';').find(c => c.trim().startsWith('MNbHKybbM92qk4aC'))
        const wallet_guest = route.params.wallet_guest
        if(!wallet_id && !wallet_guest && !route.path.includes('/subscription')){
          app.i18n.locale === "pt" ? redirect("/") : redirect(`/${app.i18n.locale}/`)
        }
      }
    }
  } else {
    if (!route.path.includes('login')){
      if(!app.$fire.auth.currentUser){
        app.i18n.locale === "pt" ? redirect("/login") : redirect(`/${app.i18n.locale}/login`)
      }
    }
  }
}
