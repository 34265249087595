export const LayoutDropdown = () => import('../../components/layout/Dropdown.vue' /* webpackChunkName: "components/layout-dropdown" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavbar = () => import('../../components/layout/Navbar.vue' /* webpackChunkName: "components/layout-navbar" */).then(c => wrapFunctional(c.default || c))
export const LayoutSidebar = () => import('../../components/layout/Sidebar.vue' /* webpackChunkName: "components/layout-sidebar" */).then(c => wrapFunctional(c.default || c))
export const SectionsCardsBlockedCard = () => import('../../components/sections/cards/BlockedCard.vue' /* webpackChunkName: "components/sections-cards-blocked-card" */).then(c => wrapFunctional(c.default || c))
export const SectionsCardsDriverChargingCard = () => import('../../components/sections/cards/DriverChargingCard.vue' /* webpackChunkName: "components/sections-cards-driver-charging-card" */).then(c => wrapFunctional(c.default || c))
export const SectionsCardsLimitUsageSection = () => import('../../components/sections/cards/LimitUsageSection.vue' /* webpackChunkName: "components/sections-cards-limit-usage-section" */).then(c => wrapFunctional(c.default || c))
export const SectionsConsumptionMyColaborators = () => import('../../components/sections/consumption/MyColaborators.vue' /* webpackChunkName: "components/sections-consumption-my-colaborators" */).then(c => wrapFunctional(c.default || c))
export const SectionsConsumptionMyCompanyConsumption = () => import('../../components/sections/consumption/MyCompanyConsumption.vue' /* webpackChunkName: "components/sections-consumption-my-company-consumption" */).then(c => wrapFunctional(c.default || c))
export const SectionsIndexActiveSubscription = () => import('../../components/sections/index/ActiveSubscription.vue' /* webpackChunkName: "components/sections-index-active-subscription" */).then(c => wrapFunctional(c.default || c))
export const SectionsIndexAvailableCardsList = () => import('../../components/sections/index/AvailableCardsList.vue' /* webpackChunkName: "components/sections-index-available-cards-list" */).then(c => wrapFunctional(c.default || c))
export const SectionsIndexChargingPointsOverallConsumption = () => import('../../components/sections/index/ChargingPointsOverallConsumption.vue' /* webpackChunkName: "components/sections-index-charging-points-overall-consumption" */).then(c => wrapFunctional(c.default || c))
export const SectionsIndexDriverLocations = () => import('../../components/sections/index/DriverLocations.vue' /* webpackChunkName: "components/sections-index-driver-locations" */).then(c => wrapFunctional(c.default || c))
export const SectionsIndexListInvoices = () => import('../../components/sections/index/ListInvoices.vue' /* webpackChunkName: "components/sections-index-list-invoices" */).then(c => wrapFunctional(c.default || c))
export const SectionsIndexMyCardsOverview = () => import('../../components/sections/index/MyCardsOverview.vue' /* webpackChunkName: "components/sections-index-my-cards-overview" */).then(c => wrapFunctional(c.default || c))
export const SectionsIndexTableGuestsDrivers = () => import('../../components/sections/index/TableGuestsDrivers.vue' /* webpackChunkName: "components/sections-index-table-guests-drivers" */).then(c => wrapFunctional(c.default || c))
export const SectionsSettingsAccount = () => import('../../components/sections/settings/Account.vue' /* webpackChunkName: "components/sections-settings-account" */).then(c => wrapFunctional(c.default || c))
export const SectionsSettingsPermissions = () => import('../../components/sections/settings/Permissions.vue' /* webpackChunkName: "components/sections-settings-permissions" */).then(c => wrapFunctional(c.default || c))
export const SectionsSettingsPreferences = () => import('../../components/sections/settings/Preferences.vue' /* webpackChunkName: "components/sections-settings-preferences" */).then(c => wrapFunctional(c.default || c))
export const SectionsSettingsWallets = () => import('../../components/sections/settings/Wallets.vue' /* webpackChunkName: "components/sections-settings-wallets" */).then(c => wrapFunctional(c.default || c))
export const SectionsSubscriptionActiveSubscription = () => import('../../components/sections/subscription/ActiveSubscription.vue' /* webpackChunkName: "components/sections-subscription-active-subscription" */).then(c => wrapFunctional(c.default || c))
export const SectionsSubscriptionCustomPlan = () => import('../../components/sections/subscription/CustomPlan.vue' /* webpackChunkName: "components/sections-subscription-custom-plan" */).then(c => wrapFunctional(c.default || c))
export const SectionsSubscriptionFrequentQuestions = () => import('../../components/sections/subscription/FrequentQuestions.vue' /* webpackChunkName: "components/sections-subscription-frequent-questions" */).then(c => wrapFunctional(c.default || c))
export const SectionsSubscriptionRetrySubscriptionCapture = () => import('../../components/sections/subscription/RetrySubscriptionCapture.vue' /* webpackChunkName: "components/sections-subscription-retry-subscription-capture" */).then(c => wrapFunctional(c.default || c))
export const SectionsSubscriptionSelectPlans = () => import('../../components/sections/subscription/SelectPlans.vue' /* webpackChunkName: "components/sections-subscription-select-plans" */).then(c => wrapFunctional(c.default || c))
export const SectionsSubscriptionInvoices = () => import('../../components/sections/subscription/SubscriptionInvoices.vue' /* webpackChunkName: "components/sections-subscription-invoices" */).then(c => wrapFunctional(c.default || c))
export const SectionsSubscriptionPaymentMethod = () => import('../../components/sections/subscription/SubscriptionPaymentMethod.vue' /* webpackChunkName: "components/sections-subscription-payment-method" */).then(c => wrapFunctional(c.default || c))
export const SectionsWalletAutoRecharge = () => import('../../components/sections/wallet/AutoRecharge.vue' /* webpackChunkName: "components/sections-wallet-auto-recharge" */).then(c => wrapFunctional(c.default || c))
export const SectionsWalletCardsTable = () => import('../../components/sections/wallet/CardsTable.vue' /* webpackChunkName: "components/sections-wallet-cards-table" */).then(c => wrapFunctional(c.default || c))
export const SectionsWalletUserVat = () => import('../../components/sections/wallet/UserVat.vue' /* webpackChunkName: "components/sections-wallet-user-vat" */).then(c => wrapFunctional(c.default || c))
export const SectionsWalletOverview = () => import('../../components/sections/wallet/WalletOverview.vue' /* webpackChunkName: "components/sections-wallet-overview" */).then(c => wrapFunctional(c.default || c))
export const SectionsWalletTopSection = () => import('../../components/sections/wallet/WalletTopSection.vue' /* webpackChunkName: "components/sections-wallet-top-section" */).then(c => wrapFunctional(c.default || c))
export const UtilsAlertsMainAlert = () => import('../../components/utils/alerts/MainAlert.vue' /* webpackChunkName: "components/utils-alerts-main-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsButtonOpenChangePaymentMethod = () => import('../../components/utils/buttons/ButtonOpenChangePaymentMethod.vue' /* webpackChunkName: "components/utils-buttons-button-open-change-payment-method" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsChangePermissions = () => import('../../components/utils/buttons/ChangePermissions.vue' /* webpackChunkName: "components/utils-buttons-change-permissions" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsMainButton = () => import('../../components/utils/buttons/MainButton.vue' /* webpackChunkName: "components/utils-buttons-main-button" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsSelectPermissions = () => import('../../components/utils/buttons/SelectPermissions.vue' /* webpackChunkName: "components/utils-buttons-select-permissions" */).then(c => wrapFunctional(c.default || c))
export const UtilsButtonsSubscriptionPriceSteps = () => import('../../components/utils/buttons/SubscriptionPriceSteps.vue' /* webpackChunkName: "components/utils-buttons-subscription-price-steps" */).then(c => wrapFunctional(c.default || c))
export const UtilsCardsPaymentMethodCard = () => import('../../components/utils/cards/PaymentMethodCard.vue' /* webpackChunkName: "components/utils-cards-payment-method-card" */).then(c => wrapFunctional(c.default || c))
export const UtilsChartsSessionSummaryChart = () => import('../../components/utils/charts/SessionSummaryChart.vue' /* webpackChunkName: "components/utils-charts-session-summary-chart" */).then(c => wrapFunctional(c.default || c))
export const UtilsDropdownsDropdownUpdatePermissions = () => import('../../components/utils/dropdowns/DropdownUpdatePermissions.vue' /* webpackChunkName: "components/utils-dropdowns-dropdown-update-permissions" */).then(c => wrapFunctional(c.default || c))
export const UtilsFormsInviteDriversForm = () => import('../../components/utils/forms/InviteDriversForm.vue' /* webpackChunkName: "components/utils-forms-invite-drivers-form" */).then(c => wrapFunctional(c.default || c))
export const UtilsFormsSearch = () => import('../../components/utils/forms/Search.vue' /* webpackChunkName: "components/utils-forms-search" */).then(c => wrapFunctional(c.default || c))
export const UtilsFormsSelectPaymentMethods = () => import('../../components/utils/forms/SelectPaymentMethods.vue' /* webpackChunkName: "components/utils-forms-select-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const UtilsFormsTimeDatePicker = () => import('../../components/utils/forms/TimeDatePicker.vue' /* webpackChunkName: "components/utils-forms-time-date-picker" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoadersLoading = () => import('../../components/utils/loaders/loading.vue' /* webpackChunkName: "components/utils-loaders-loading" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalAddDrivers = () => import('../../components/utils/modal/ModalAddDrivers.vue' /* webpackChunkName: "components/utils-modal-add-drivers" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalAddFunds = () => import('../../components/utils/modal/ModalAddFunds.vue' /* webpackChunkName: "components/utils-modal-add-funds" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalAddVehicles = () => import('../../components/utils/modal/ModalAddVehicles.vue' /* webpackChunkName: "components/utils-modal-add-vehicles" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalAddress = () => import('../../components/utils/modal/ModalAddress.vue' /* webpackChunkName: "components/utils-modal-address" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalChangeActiveWallets = () => import('../../components/utils/modal/ModalChangeActiveWallets.vue' /* webpackChunkName: "components/utils-modal-change-active-wallets" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalChangeDefaultPaymentMethod = () => import('../../components/utils/modal/ModalChangeDefaultPaymentMethod.vue' /* webpackChunkName: "components/utils-modal-change-default-payment-method" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalChangePaymentMethodUnlock = () => import('../../components/utils/modal/ModalChangePaymentMethodUnlock.vue' /* webpackChunkName: "components/utils-modal-change-payment-method-unlock" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalChangeSubscriptionPaymentMethod = () => import('../../components/utils/modal/ModalChangeSubscriptionPaymentMethod.vue' /* webpackChunkName: "components/utils-modal-change-subscription-payment-method" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalCreatePaymentMethod = () => import('../../components/utils/modal/ModalCreatePaymentMethod.vue' /* webpackChunkName: "components/utils-modal-create-payment-method" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalInfoPopUp = () => import('../../components/utils/modal/ModalInfoPopUp.vue' /* webpackChunkName: "components/utils-modal-info-pop-up" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalInviteDriverGuest = () => import('../../components/utils/modal/ModalInviteDriverGuest.vue' /* webpackChunkName: "components/utils-modal-invite-driver-guest" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalPayUnlockWallet = () => import('../../components/utils/modal/ModalPayUnlockWallet.vue' /* webpackChunkName: "components/utils-modal-pay-unlock-wallet" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalUpdateSubscriptions = () => import('../../components/utils/modal/ModalUpdateSubscriptions.vue' /* webpackChunkName: "components/utils-modal-update-subscriptions" */).then(c => wrapFunctional(c.default || c))
export const UtilsModalUpdateSuspendedSubscription = () => import('../../components/utils/modal/ModalUpdateSuspendedSubscription.vue' /* webpackChunkName: "components/utils-modal-update-suspended-subscription" */).then(c => wrapFunctional(c.default || c))
export const V2CardsCardOrderSummary = () => import('../../components/v2/cards/CardOrderSummary.vue' /* webpackChunkName: "components/v2-cards-card-order-summary" */).then(c => wrapFunctional(c.default || c))
export const V2CardsCardOrdersList = () => import('../../components/v2/cards/CardOrdersList.vue' /* webpackChunkName: "components/v2-cards-card-orders-list" */).then(c => wrapFunctional(c.default || c))
export const V2CardsCredit = () => import('../../components/v2/cards/Credit.vue' /* webpackChunkName: "components/v2-cards-credit" */).then(c => wrapFunctional(c.default || c))
export const V2CardsListSelectedCards = () => import('../../components/v2/cards/ListSelectedCards.vue' /* webpackChunkName: "components/v2-cards-list-selected-cards" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboarding = () => import('../../components/v2/cards/Onboarding.vue' /* webpackChunkName: "components/v2-cards-onboarding" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingBilling = () => import('../../components/v2/cards/OnboardingBilling.vue' /* webpackChunkName: "components/v2-cards-onboarding-billing" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingCardsNumber = () => import('../../components/v2/cards/OnboardingCardsNumber.vue' /* webpackChunkName: "components/v2-cards-onboarding-cards-number" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingCustomization = () => import('../../components/v2/cards/OnboardingCustomization.vue' /* webpackChunkName: "components/v2-cards-onboarding-customization" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingEnergyCycle = () => import('../../components/v2/cards/OnboardingEnergyCycle.vue' /* webpackChunkName: "components/v2-cards-onboarding-energy-cycle" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingOrderMessage = () => import('../../components/v2/cards/OnboardingOrderMessage.vue' /* webpackChunkName: "components/v2-cards-onboarding-order-message" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingPayment = () => import('../../components/v2/cards/OnboardingPayment.vue' /* webpackChunkName: "components/v2-cards-onboarding-payment" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingSelectProduct = () => import('../../components/v2/cards/OnboardingSelectProduct.vue' /* webpackChunkName: "components/v2-cards-onboarding-select-product" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingShipping = () => import('../../components/v2/cards/OnboardingShipping.vue' /* webpackChunkName: "components/v2-cards-onboarding-shipping" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingStart = () => import('../../components/v2/cards/OnboardingStart.vue' /* webpackChunkName: "components/v2-cards-onboarding-start" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingTabsNavigation = () => import('../../components/v2/cards/OnboardingTabsNavigation.vue' /* webpackChunkName: "components/v2-cards-onboarding-tabs-navigation" */).then(c => wrapFunctional(c.default || c))
export const V2CardsOnboardingTerms = () => import('../../components/v2/cards/OnboardingTerms.vue' /* webpackChunkName: "components/v2-cards-onboarding-terms" */).then(c => wrapFunctional(c.default || c))
export const V2CardsRenovation = () => import('../../components/v2/cards/Renovation.vue' /* webpackChunkName: "components/v2-cards-renovation" */).then(c => wrapFunctional(c.default || c))
export const V2FormsChangeLanguage = () => import('../../components/v2/forms/ChangeLanguage.vue' /* webpackChunkName: "components/v2-forms-change-language" */).then(c => wrapFunctional(c.default || c))
export const V2FormsDropdown = () => import('../../components/v2/forms/Dropdown.vue' /* webpackChunkName: "components/v2-forms-dropdown" */).then(c => wrapFunctional(c.default || c))
export const V2FormsNumberOfResults = () => import('../../components/v2/forms/NumberOfResults.vue' /* webpackChunkName: "components/v2-forms-number-of-results" */).then(c => wrapFunctional(c.default || c))
export const V2FormsPagination = () => import('../../components/v2/forms/Pagination.vue' /* webpackChunkName: "components/v2-forms-pagination" */).then(c => wrapFunctional(c.default || c))
export const V2FormsSearch = () => import('../../components/v2/forms/Search.vue' /* webpackChunkName: "components/v2-forms-search" */).then(c => wrapFunctional(c.default || c))
export const V2FormsSelectOption = () => import('../../components/v2/forms/SelectOption.vue' /* webpackChunkName: "components/v2-forms-select-option" */).then(c => wrapFunctional(c.default || c))
export const V2InvoicesModalInvoicePayment = () => import('../../components/v2/invoices/ModalInvoicePayment.vue' /* webpackChunkName: "components/v2-invoices-modal-invoice-payment" */).then(c => wrapFunctional(c.default || c))
export const V2ModalCardOrderError = () => import('../../components/v2/modal/CardOrderError.vue' /* webpackChunkName: "components/v2-modal-card-order-error" */).then(c => wrapFunctional(c.default || c))
export const V2ModalCardOrderSlide = () => import('../../components/v2/modal/CardOrderSlide.vue' /* webpackChunkName: "components/v2-modal-card-order-slide" */).then(c => wrapFunctional(c.default || c))
export const V2ModalCardStockWarning = () => import('../../components/v2/modal/CardStockWarning.vue' /* webpackChunkName: "components/v2-modal-card-stock-warning" */).then(c => wrapFunctional(c.default || c))
export const V2ModalChangeCredit = () => import('../../components/v2/modal/ChangeCredit.vue' /* webpackChunkName: "components/v2-modal-change-credit" */).then(c => wrapFunctional(c.default || c))
export const V2ModalChangeRenovation = () => import('../../components/v2/modal/ChangeRenovation.vue' /* webpackChunkName: "components/v2-modal-change-renovation" */).then(c => wrapFunctional(c.default || c))
export const V2ModalExportCards = () => import('../../components/v2/modal/ExportCards.vue' /* webpackChunkName: "components/v2-modal-export-cards" */).then(c => wrapFunctional(c.default || c))
export const V2ModalExportSessions = () => import('../../components/v2/modal/ExportSessions.vue' /* webpackChunkName: "components/v2-modal-export-sessions" */).then(c => wrapFunctional(c.default || c))
export const V2ModalInfoAddCredit = () => import('../../components/v2/modal/InfoAddCredit.vue' /* webpackChunkName: "components/v2-modal-info-add-credit" */).then(c => wrapFunctional(c.default || c))
export const V2ModalInfoRenovation = () => import('../../components/v2/modal/InfoRenovation.vue' /* webpackChunkName: "components/v2-modal-info-renovation" */).then(c => wrapFunctional(c.default || c))
export const V2ModalExportInvoices = () => import('../../components/v2/modal/ModalExportInvoices.vue' /* webpackChunkName: "components/v2-modal-export-invoices" */).then(c => wrapFunctional(c.default || c))
export const V2ModalNewModal = () => import('../../components/v2/modal/NewModal.vue' /* webpackChunkName: "components/v2-modal-new-modal" */).then(c => wrapFunctional(c.default || c))
export const V2VehiclesCard = () => import('../../components/v2/vehicles/Card.vue' /* webpackChunkName: "components/v2-vehicles-card" */).then(c => wrapFunctional(c.default || c))
export const SectionsSettingsSectionsInviteGuests = () => import('../../components/sections/settings/sections/InviteGuests.vue' /* webpackChunkName: "components/sections-settings-sections-invite-guests" */).then(c => wrapFunctional(c.default || c))
export const SectionsSettingsSectionsManageGuests = () => import('../../components/sections/settings/sections/ManageGuests.vue' /* webpackChunkName: "components/sections-settings-sections-manage-guests" */).then(c => wrapFunctional(c.default || c))
export const SectionsSettingsSectionsManagePermissions = () => import('../../components/sections/settings/sections/ManagePermissions.vue' /* webpackChunkName: "components/sections-settings-sections-manage-permissions" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsAddFundsWithData = () => import('../../components/v2/payments/add-funds/AddFundsWithData.vue' /* webpackChunkName: "components/v2-payments-add-funds-with-data" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsPaymentLogicChange = () => import('../../components/v2/payments/payment-logic/PaymentLogicChange.vue' /* webpackChunkName: "components/v2-payments-payment-logic-change" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsPaymentMethodsDefaultPaymentMethod = () => import('../../components/v2/payments/payment-methods/DefaultPaymentMethod.vue' /* webpackChunkName: "components/v2-payments-payment-methods-default-payment-method" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsPaymentMethodsMbConfirm = () => import('../../components/v2/payments/payment-methods/MbConfirm.vue' /* webpackChunkName: "components/v2-payments-payment-methods-mb-confirm" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsPaymentMethodsMbReference = () => import('../../components/v2/payments/payment-methods/MbReference.vue' /* webpackChunkName: "components/v2-payments-payment-methods-mb-reference" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsPaymentMethodsMbwayConfirm = () => import('../../components/v2/payments/payment-methods/MbwayConfirm.vue' /* webpackChunkName: "components/v2-payments-payment-methods-mbway-confirm" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsPaymentMethodsMbwayOptions = () => import('../../components/v2/payments/payment-methods/MbwayOptions.vue' /* webpackChunkName: "components/v2-payments-payment-methods-mbway-options" */).then(c => wrapFunctional(c.default || c))
export const V2PaymentsPaymentMethodsList = () => import('../../components/v2/payments/payment-methods/PaymentMethodsList.vue' /* webpackChunkName: "components/v2-payments-payment-methods-list" */).then(c => wrapFunctional(c.default || c))
export const V2WalletsCreateModalCannotCreateWallet = () => import('../../components/v2/wallets/create/ModalCannotCreateWallet.vue' /* webpackChunkName: "components/v2-wallets-create-modal-cannot-create-wallet" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
