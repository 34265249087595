import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _02ffe4d2 = () => interopDefault(import('../pages/cards.vue' /* webpackChunkName: "pages/cards" */))
const _8d6dfca6 = () => interopDefault(import('../pages/charging-sessions.vue' /* webpackChunkName: "pages/charging-sessions" */))
const _1454f8cf = () => interopDefault(import('../pages/consumption.vue' /* webpackChunkName: "pages/consumption" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fd83926c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _56f52f5b = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _2eb8163e = () => interopDefault(import('../pages/invites.vue' /* webpackChunkName: "pages/invites" */))
const _2d33afe2 = () => interopDefault(import('../pages/invoices.vue' /* webpackChunkName: "pages/invoices" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f7a93ac2 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _0546df48 = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _6bf27990 = () => interopDefault(import('../pages/wallet/index.vue' /* webpackChunkName: "pages/wallet/index" */))
const _cf86fcf2 = () => interopDefault(import('../pages/fleets/add-new-fleet.vue' /* webpackChunkName: "pages/fleets/add-new-fleet" */))
const _4f529cb2 = () => interopDefault(import('../pages/subscription/subscribe-plan.vue' /* webpackChunkName: "pages/subscription/subscribe-plan" */))
const _1808a9ad = () => interopDefault(import('../pages/wallet/settings.vue' /* webpackChunkName: "pages/wallet/settings" */))
const _1f5a1fbc = () => interopDefault(import('../pages/wallet-guest/_wallet_guest.vue' /* webpackChunkName: "pages/wallet-guest/_wallet_guest" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cards",
    component: _02ffe4d2,
    name: "cards___pt"
  }, {
    path: "/charging-sessions",
    component: _8d6dfca6,
    name: "charging-sessions___pt"
  }, {
    path: "/consumption",
    component: _1454f8cf,
    name: "consumption___pt"
  }, {
    path: "/en",
    component: _482400f4,
    name: "index___en"
  }, {
    path: "/es",
    component: _482400f4,
    name: "index___es"
  }, {
    path: "/faq",
    component: _fd83926c,
    name: "faq___pt"
  }, {
    path: "/fr",
    component: _482400f4,
    name: "index___fr"
  }, {
    path: "/home",
    component: _56f52f5b,
    name: "home___pt"
  }, {
    path: "/invites",
    component: _2eb8163e,
    name: "invites___pt"
  }, {
    path: "/invoices",
    component: _2d33afe2,
    name: "invoices___pt"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login___pt"
  }, {
    path: "/settings",
    component: _f7a93ac2,
    name: "settings___pt"
  }, {
    path: "/subscription",
    component: _0546df48,
    name: "subscription___pt"
  }, {
    path: "/wallet",
    component: _6bf27990,
    name: "wallet___pt"
  }, {
    path: "/en/cards",
    component: _02ffe4d2,
    name: "cards___en"
  }, {
    path: "/en/charging-sessions",
    component: _8d6dfca6,
    name: "charging-sessions___en"
  }, {
    path: "/en/consumption",
    component: _1454f8cf,
    name: "consumption___en"
  }, {
    path: "/en/faq",
    component: _fd83926c,
    name: "faq___en"
  }, {
    path: "/en/home",
    component: _56f52f5b,
    name: "home___en"
  }, {
    path: "/en/invites",
    component: _2eb8163e,
    name: "invites___en"
  }, {
    path: "/en/invoices",
    component: _2d33afe2,
    name: "invoices___en"
  }, {
    path: "/en/login",
    component: _b4d4bac6,
    name: "login___en"
  }, {
    path: "/en/settings",
    component: _f7a93ac2,
    name: "settings___en"
  }, {
    path: "/en/subscription",
    component: _0546df48,
    name: "subscription___en"
  }, {
    path: "/en/wallet",
    component: _6bf27990,
    name: "wallet___en"
  }, {
    path: "/es/cards",
    component: _02ffe4d2,
    name: "cards___es"
  }, {
    path: "/es/charging-sessions",
    component: _8d6dfca6,
    name: "charging-sessions___es"
  }, {
    path: "/es/consumption",
    component: _1454f8cf,
    name: "consumption___es"
  }, {
    path: "/es/faq",
    component: _fd83926c,
    name: "faq___es"
  }, {
    path: "/es/home",
    component: _56f52f5b,
    name: "home___es"
  }, {
    path: "/es/invites",
    component: _2eb8163e,
    name: "invites___es"
  }, {
    path: "/es/invoices",
    component: _2d33afe2,
    name: "invoices___es"
  }, {
    path: "/es/login",
    component: _b4d4bac6,
    name: "login___es"
  }, {
    path: "/es/settings",
    component: _f7a93ac2,
    name: "settings___es"
  }, {
    path: "/es/subscription",
    component: _0546df48,
    name: "subscription___es"
  }, {
    path: "/es/wallet",
    component: _6bf27990,
    name: "wallet___es"
  }, {
    path: "/fleets/add-new-fleet",
    component: _cf86fcf2,
    name: "fleets-add-new-fleet___pt"
  }, {
    path: "/fr/cards",
    component: _02ffe4d2,
    name: "cards___fr"
  }, {
    path: "/fr/charging-sessions",
    component: _8d6dfca6,
    name: "charging-sessions___fr"
  }, {
    path: "/fr/consumption",
    component: _1454f8cf,
    name: "consumption___fr"
  }, {
    path: "/fr/faq",
    component: _fd83926c,
    name: "faq___fr"
  }, {
    path: "/fr/home",
    component: _56f52f5b,
    name: "home___fr"
  }, {
    path: "/fr/invites",
    component: _2eb8163e,
    name: "invites___fr"
  }, {
    path: "/fr/invoices",
    component: _2d33afe2,
    name: "invoices___fr"
  }, {
    path: "/fr/login",
    component: _b4d4bac6,
    name: "login___fr"
  }, {
    path: "/fr/settings",
    component: _f7a93ac2,
    name: "settings___fr"
  }, {
    path: "/fr/subscription",
    component: _0546df48,
    name: "subscription___fr"
  }, {
    path: "/fr/wallet",
    component: _6bf27990,
    name: "wallet___fr"
  }, {
    path: "/subscription/subscribe-plan",
    component: _4f529cb2,
    name: "subscription-subscribe-plan___pt"
  }, {
    path: "/wallet/settings",
    component: _1808a9ad,
    name: "wallet-settings___pt"
  }, {
    path: "/en/fleets/add-new-fleet",
    component: _cf86fcf2,
    name: "fleets-add-new-fleet___en"
  }, {
    path: "/en/subscription/subscribe-plan",
    component: _4f529cb2,
    name: "subscription-subscribe-plan___en"
  }, {
    path: "/en/wallet/settings",
    component: _1808a9ad,
    name: "wallet-settings___en"
  }, {
    path: "/es/fleets/add-new-fleet",
    component: _cf86fcf2,
    name: "fleets-add-new-fleet___es"
  }, {
    path: "/es/subscription/subscribe-plan",
    component: _4f529cb2,
    name: "subscription-subscribe-plan___es"
  }, {
    path: "/es/wallet/settings",
    component: _1808a9ad,
    name: "wallet-settings___es"
  }, {
    path: "/fr/fleets/add-new-fleet",
    component: _cf86fcf2,
    name: "fleets-add-new-fleet___fr"
  }, {
    path: "/fr/subscription/subscribe-plan",
    component: _4f529cb2,
    name: "subscription-subscribe-plan___fr"
  }, {
    path: "/fr/wallet/settings",
    component: _1808a9ad,
    name: "wallet-settings___fr"
  }, {
    path: "/en/wallet-guest/:wallet_guest",
    component: _1f5a1fbc,
    name: "wallet-guest-wallet_guest___en"
  }, {
    path: "/es/wallet-guest/:wallet_guest",
    component: _1f5a1fbc,
    name: "wallet-guest-wallet_guest___es"
  }, {
    path: "/fr/wallet-guest/:wallet_guest",
    component: _1f5a1fbc,
    name: "wallet-guest-wallet_guest___fr"
  }, {
    path: "/wallet-guest/:wallet_guest",
    component: _1f5a1fbc,
    name: "wallet-guest-wallet_guest___pt"
  }, {
    path: "/",
    component: _482400f4,
    name: "index___pt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
