
import { mapGetters } from "vuex"
import FormatDates from "@/functions/FormatDates"
export default {
  mixins: [FormatDates],
  data(){
    return {
      index: 0,
      messages: [],
      timeoutID: null
    }
  },
  async mounted(){
    this.addMessages()
    this.changeMessage()
  },
  watch: {
    activeSubscription(){
      this.addMessages()
      this.changeMessage()
    },
    "$i18n.locale"(){
      this.addMessages()
      this.changeMessage()
    }
  },
  beforeDestroy() {
    clearInterval(this.timeoutID)
  },
  methods: {
    changeMessage(){
      clearInterval(this.timeoutID)
      this.index = (this.index + 1) % this.messages.length
      this.timeoutID = setInterval(this.changeMessage, 5000)
    },
    addMessages(){
      this.messages = []
      this.messages.push(this.$t('newUpdateString', { update: `<strong>${this.$t('newUpdate')}</strong>` }))
      if(this.activeSubscription) {
        const plan = this.activeSubscription.subscription_plan.name
        const date = this.getFullDate(this.activeSubscription.valid_until)
        const message = this.$t('subscriptionPlan', { plan: `<strong>${plan}</strong>`, date: `<strong>${date}</strong>` })
        this.messages.push(message)
      }
      if(this.verifyEndingSubscription) {
        const date = this.getFullDate(this.activeSubscription.valid_until)
        const message = this.$t('endingSubscription', { date: `<strong>${date}</strong>` })
        this.messages.push(message)
      }
    }
  },
  computed: {
    ...mapGetters(["activeSubscription", "verifyEndingSubscription"]),
  }
}
