import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import moment from 'moment'
import qs from 'qs'
Vue.use(Vuex)
function allowCommas(str) {
  return encodeURIComponent(str).replace(/%2C/g, ',')
}
export const state = () => ({
  user: null,
  userToken: null,
  wallet_id: null,
  guest_invite: null,
  redirect: null,
  legal_terms: {
    miio: "https://miio-app-photos.s3.eu-west-3.amazonaws.com//docs/Condi%C3%A7%C3%B5es+Gerais+de+Fornecimento+de+Eletricidade+para+a+Mobilidade+El%C3%A9trica+.pdf",
    mobie: "https://www.mobie.pt/documents/42032/192209/Condi%C3%A7%C3%B5es+de+Utiliza%C3%A7%C3%A3o+da+Rede+de+Mobilidade+El%C3%A9trica_Fase+de+Mercado.pdf/21d83f9a-7936-fcf3-5dde-88dcf593f058?t=1634730974710",
    sepa: "https://miio-app-photos.s3.eu-west-3.amazonaws.com//docs/miio-charge%26pay_SEPA.pdf"
  },
  routes: [
    { id: 0, name: "index", fullName: 'selectWallet', permissions: 0 },
    { id: 1, name: "home", fullName: 'homePage', permissions: 2 },
    { id: 2, name: "wallet", fullName: 'wallet', permissions: 2 },
    { id: 3, name: "cards", fullName: 'cards', permissions: 2 },
    { id: 4, name: "invoices", fullName: 'invoices', permissions: 2 },
    { id: 5, name: "charging-sessions", fullName: 'chargingSessions', permissions: 2 },
    { id: 6, name: "consumption", fullName: 'consumptions', permissions: 2 },
  ],
  GLOBAL: {
    user: null,
    wallet: null,
    wallet_overview: null,
    subscription: null,
  },
  headers: {
    "X-TOKEN": null,
    "X-PRODUCT": "FLEETS_DASHBOARD",
    ...(!process.env.IS_PROD ? {
      "CF-Access-Client-Id": process.env.CF_ACCESS_CLIENT_ID,
      "CF-Access-Client-Secret": process.env.CF_ACCESS_CLIENT_SECRET
    } : {})
  },
  alert: null
})

export const actions = {
  async onAuthStateChangedAction(state, { authUser, claims }) {
    if (!authUser){
      Cookies.remove('Fgv2MJp5P5ukK4Vu') // USER TOKEN
      Cookies.remove('MNbHKybbM92qk4aC') // WALLET ID
      Cookies.remove('ZGCmCKTJLQpGtmUD') // PERMISSIONS
      if(this.state.guest_invite){
        await this.$router.push({ name: `login___${this.$i18n.locale}`, params: { guest_invite: this.state.guest_invite }})
      } else {
        await this.$router.push({ name: `login___${this.$i18n.locale}`})
      }
      state.commit('CLEAR_USER_DATA')
      this.$sentry.setUser(null)
    } else {
      const { uid, email } = authUser
      try {
        const userToken = await this.$fire.auth.currentUser.getIdToken(true)
        state.commit('SET_USER_TOKEN', userToken)
        state.commit('SET_USER', { uid, email })
        Cookies.set('Fgv2MJp5P5ukK4Vu', userToken)
      } catch (_) {}
      if (state.state.redirect) {
        await this.$router.push({ name: `${state.state.redirect}___${this.$i18n.locale}`})
        state.commit("SET_PAGE_REDIRECT", null)
      } else {
        await this.$router.push({ name: `index___${this.$i18n.locale}`})
      }
      try {
        const user_data = state.dispatch('getUserDetails')
        const subscription = state.dispatch('getListOfWalletSubscriptions', ["ACTIVE"])
        let [userData, userSubscription] = await Promise.all([user_data, subscription])
        state.commit('SET_GLOBAL_USER_DATA', userData.data)
        if (userSubscription.status === 200 && userSubscription.data.results.length === 0) {
          userSubscription = await state.dispatch('getListOfWalletSubscriptions', ["SUSPENDED"])
        }
        if (userSubscription.data.results.length > 0) {
          let plan_details = userSubscription.data.results[0].subscription_plan.plan_details
          const sortSubscriptionArr = ["n_wallets", "n_members", "n_detailed_cards", "n_guests", "traditional_client_support", "invoices_export_after_twelve_months", "free_personalized_card", "dedicated_whatsapp", "invoices_bulk_export", "api_integration"]
          let sort = plan_details.sort(function (a, b) {
            return sortSubscriptionArr.indexOf(a.name) - sortSubscriptionArr.indexOf(b.name)
          })
          plan_details = sort
          state.commit('SET_GLOBAL_SUBSCRIPTION', userSubscription.data.results[0])
        }
      } catch (_) {}
      try {
        const wallet_id = Cookies.get('MNbHKybbM92qk4aC')
        if (wallet_id) {
          state.commit('SET_WALLET_ID', wallet_id)
          let wallet_details = state.dispatch('getWalletDetails')
          let wallet_overview = state.dispatch('getUserWalletOverview')
          const [walletDetails, walletOverview] = await Promise.all([wallet_details, wallet_overview])
          state.commit('SET_GLOBAL_WALLET_DETAILS', walletDetails.data)
          state.commit('SET_GLOBAL_WALLET_OVERVIEW', walletOverview.data)
        }
      } catch (_) {}
      this.$sentry.setUser({ id: uid, email: email })
    }
  },

  // MAIN PAGE
  getUserDetails({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/users"
    return this.$axios.get(url, { headers: state.headers })
  },
  updateUserDetails({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/users"
    return this.$axios.patch(url, body, {headers: state.headers })
  },

  getWalletPaymentMethodDetails({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/gateway/user/payment-methods/" + id
    return this.$axios.get(url, {headers: state.headers })
  },

  createBusinessWallet({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  blockBusinessWallets({state}, [wallets]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallets/block"
    return this.$axios.post(url, wallets, {headers: state.headers })
  },

  listUserWallets({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet"
    return this.$axios.get(url, {headers: state.headers })
  },

  getUserWalletOverview({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/overview"
    return this.$axios.get(url, {headers: state.headers })
  },

  getWalletDetails({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id
    return this.$axios.get(url, {headers: state.headers })
  },

  updateWalletDetails({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id
    return this.$axios.put(url, body, {headers: state.headers })
  },

  getCardsList({state}, [search, limit, offset]){
    let url = `${process.env.MIIO_SERVICES_URL}/v3/user/wallet/${state.wallet_id}/cards/?limit=${limit}&offset=${offset}`
    if(search){
      url += "&search=" + search
    }
    return this.$axios.get(url, {headers: state.headers })
  },

  listChargingCards({state}, [offset, search]){
    let url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/cards/?limit=10&offset=" + offset
    if(search){
      url += "&search=" + search
    }
    return this.$axios.get(url, {headers: state.headers })
  },

  getMemberDetails({state}, [member_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/members/" + member_id
    return this.$axios.get(url, {headers: state.headers })
  },

  getMembersList({state}, [search]){
    let url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/members?limit=100"
    if(search){
      url += "&search=" + search
    }
    return this.$axios.get(url, {headers: state.headers })
  },

  blockCard({state}, [pk_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/cards/" + pk_id + "/block/"
    return this.$axios.post(url, null, {headers: state.headers })
  },

  unblockCard({state}, [pk_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/cards/" + pk_id + "/unblock/"
    return this.$axios.post(url, null, {headers: state.headers })
  },

  // REQUEST CARDS
  placeCardOrder({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/request-card-v3"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  listCardOrders({state}, [isEkey, offset, limit]){
    const url = process.env.MIIO_SERVICES_URL + `/v3/user/wallet/${state.wallet_id}/card-orders?is_ekey=${isEkey}&offset=${offset}&limit=${limit}`
    return this.$axios.get(url, {headers: state.headers })
  },

  getCardPrices({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v4/charge-and-pay/prices?wallet=" + state.wallet_id
    return this.$axios.get(url, {headers: state.headers })
  },


  // USER VAT SETTINGS
  getUserVat({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/nifs"
    return this.$axios.get(url, {headers: state.headers })
  },

  getVatDetails({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/nifs/" + id
    return this.$axios.get(url, {headers: state.headers })
  },

  createUserVat({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/nifs"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  editUserVat({state}, [id, body]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/nifs/" + id
    return this.$axios.patch(url, body, {headers: state.headers })
  },

  deleteUserVat({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/nifs/" + id
    return this.$axios.delete(url, {headers: state.headers })
  },

  // INVITE DRIVERS
  sendDriverInvite({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/members/send-invitation"
    return this.$axios.post(url, body, {headers: state.headers })
  },
  sendDriverInviteViaEmail({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/members/send-invitation-email"
    return this.$axios.post(url, body, {headers: state.headers })
  },
  sendDriverInviteViaSMS({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/members/send-invitation-sms"
    return this.$axios.post(url, body, {headers: state.headers })
  },
  inviteDriverViaLink({state}, [token_pk]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/tokens/" + token_pk + "/share-link"
    return this.$axios.get(url, {headers: state.headers })
  },

  getAvailableDriverInvites({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/overview"
    return this.$axios.get(url, {headers: state.headers })
  },

  // CONSUMPTION
  getCompanyConsumption({state}, [view]){
    const url = process.env.MIIO_API_URL + "/v4/fleets/dashboard-consumption/" + state.wallet_id + "?view=" + view
    return this.$axios.get(url, {headers: state.headers })
  },

  getWalletMemberConsumption({state}, [visual_number, member]){
    let url = process.env.MIIO_API_URL + "/v4/fleets/dashboard-consumption/" + state.wallet_id + "/cards/" + visual_number + "?member=" + member + "&view=month"
    return this.$axios.get(url, {headers: state.headers  })
  },

  getListOfConsumptions({state}, [search, offset]){
    let url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/consumptions?limit=8&offset=" + offset
    if(search){
      url += "&search=" + search
    }
    return this.$axios.get(url, {headers: state.headers })
  },

  getWalletConsumption({state}){
    const url = process.env.MIIO_API_URL + "/v4/fleets/dashboard-consumption/" + state.wallet_id + "/chargers"
    return this.$axios.get(url, {headers: state.headers })
  },


  // INVOICES
  getInvoices({state}, [filters]){
    let url = `${process.env.MIIO_API_URL}/v4/user/wallet/${state.wallet_id}/invoices?`
    let params = []
    for (const key in filters) {
      if (filters[key] !== null && filters[key] !== undefined) {
        params.push(`${key}=${encodeURIComponent(filters[key])}`)
      }
    }
    url += params.join('&')
    return this.$axios.get(url, { headers: state.headers })
  },


  getPresignedInvoiceUrl({ state }, [type, ids]){
    const idsString = ids.length > 1 ? ids.map(id => `&ids=${id}`).join('') : `&ids=${ids}`
    const endpoint = `${process.env.MIIO_SERVICES_URL}/v2/user/invoices/list_download?type=${type}${idsString}`
    return this.$axios.get(endpoint, { headers: state.headers })
  },

  exportInvoicesInBulk({state}, [invoices, credit_notes]){
    const url = `${process.env.MIIO_SERVICES_URL}/v4/user/export-invoices-zip`
    const payload = { invoices, credit_notes }
    return this.$axios.post(url, payload, { headers: state.headers })
  },

  retryInvoiceCapture({state}, [invoice_id, payment_method]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/invoice/" + invoice_id + "/retry-capture"
    return this.$axios.post(url, payment_method, {headers: state.headers })
  },


  getListOfChargingSessions({state}, [limit, offset, search, ordering, start_date_time__gte, start_date_time__lte, status]){
    const baseUrl = `${process.env.MIIO_API_URL}/v5/${state.wallet_id}/sessions`
    const params = { limit, offset, search, ordering, start_date_time__gte, start_date_time__lte, status }
    const queryString = qs.stringify(params, { skipNulls: true, encoder: allowCommas })
    const url = queryString ? `${baseUrl}?${queryString}` : baseUrl
    return this.$axios.get(url, { headers: state.headers })
  },

  getChargingSessionDetails({state}, [session_pk_id]){
    const url = process.env.MIIO_API_URL + "/v4/" + state.wallet_id + "/sessions/detail/" + session_pk_id
    return this.$axios.get(url, {headers: state.headers })
  },

  getChargingSessionPlot({state}, [session_pk_id]){
    const url = process.env.MIIO_API_URL + "/v4/" + state.wallet_id + "/sessions/plot/" + session_pk_id
    return this.$axios.get(url, {headers: state.headers })
  },

  stopChargingSession({state}, [body]){
    const url = process.env.MIIO_API_URL + "/v4/commands/request-command/STOP_SESSION"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  // ADD FUNDS
  addWalletFunds({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/wallet/" + state.wallet_id + "/add-funds"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  // PAYMENT METHODS
  getUserPaymentMethods({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/gateway/user/payment-methods"
    return this.$axios.get(url, {headers: state.headers })
  },

  createPaymentMethod({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/gateway/user/payment-methods"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  authorizeMbwayPayments({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/gateway/user/payment-methods/" + id + "/mandates"
    return this.$axios.post(url, {}, {headers: state.headers })
  },

  updatePaymentMethod({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/gateway/user/payment-methods/" + id
    return this.$axios.patch(url, {headers: state.headers })
  },

  deletePaymentMethod({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/gateway/user/payment-methods/" + id
    return this.$axios.delete(url, {headers: state.headers })
  },

  // ADDRESS
  getUserShippingAddresses({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/shipping-address"
    return this.$axios.get(url, {headers: state.headers })
  },

  getCountriesList({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/shipping-address/countries"
    return this.$axios.get(url, {headers: state.headers })
  },
  createShippingAddress({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/shipping-address"
    return this.$axios.post(url, body, {headers: state.headers })
  },
  deleteShippingAddress({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/shipping-address/" + id
    return this.$axios.delete(url, {headers: state.headers })
  },

  // VEHICLES
  getUserVehicles({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/cars"
    return this.$axios.get(url, { headers: state.headers })
  },

  getVehiclesBrands({state}, [type]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/vehicles-brands/" + type
    return this.$axios.get(url, { headers: state.headers })
  },
  getVehicleModels({state}, [type, brand_name]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/vehicles?search=" + type + " " + brand_name
    return this.$axios.get(url, { headers: state.headers })
  },

  createNewVehicle({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/user/cars"
    return this.$axios.post(url, body, { headers: state.headers })
  },

  updateVehicle({state}, [id, body]){
    const url =`${process.env.MIIO_SERVICES_URL}/v2/user/cars/${id}`
    return this.$axios.patch(url, body, { headers: state.headers })
  },

  removeVehicle({state}, [id]){
    const url = `${process.env.MIIO_SERVICES_URL}/v2/user/cars/${id}`
    return this.$axios.delete(url, { headers: state.headers })
  },

  // UPDATE CARDS
  updateCard({state}, [pk_id, body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/cards/" + pk_id + "/"
    return this.$axios.patch(url, body, {headers: state.headers })
  },
  // AUTO RECHARGE
  getAutoRechargeDetails({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/wallet/" + state.wallet_id + "/auto-recharge"
    return this.$axios.get(url, {headers: state.headers })
  },

  createAutoRecharge({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v2/wallet/" + state.wallet_id + "/auto-recharge"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  getDriversLocations({state}){
    const url = process.env.MIIO_API_URL + "/v4/wallet/" + state.wallet_id + "/members/last-known-location"
    return this.$axios.get(url, {headers: state.headers })
  },


  // SUBSCRIPTIONS
  getSubscriptionPlans({state}){
    const url = process.env.MIIO_API_URL + "/v4/subscriptions/subscription_plans?target=BUSINESS&product=FLEETS_DASHBOARD"
    return this.$axios.get(url, {headers: state.headers })
  },

  changeSubscriptionPlan({state}, [id, body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/" + id + "/change-subscription-plan/"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  updateSubscription({state}, [id, body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/" + id + "/"
    return this.$axios.patch(url, body, {headers: state.headers })
  },

  // GET DETAILS OF AN ACTIVE SUBSCRIPTION
  getSubscriptionById({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/" + id + "/"
    return this.$axios.get(url, {headers: state.headers })
  },

  getPlanValueOnChangeSubscription({state}, [subscription_id, price_step]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/" + subscription_id + "/change-subscription-plan/?new_price_step=" + price_step
    return this.$axios.get(url, {headers: state.headers })
  },


  // GET PLAN DETAILS
  getSubscriptionPlanById({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/subscription-plan/" + id + "/"
    return this.$axios.get(url, {headers: state.headers })
  },

  // GET CURRENT PLAN FEATURES LIST
  getSubscriptionPlanDetails({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/subscription-plan-details/"
    return this.$axios.get(url, {headers: state.headers })
  },

  // LIST ALL PLANS
  getListOfSubscriptionPlans({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/subscription-plan/"
    return this.$axios.get(url, {headers: state.headers })
  },

  // GET USER'S SUBSCRIPTIONS LIST
  getListOfWalletSubscriptions({state}, [subscription_state]){
    const url = process.env.MIIO_API_URL + "/v4/subscriptions/subscriptions?product=FLEETS_DASHBOARD&target=BUSINESS&ordering=-id&subscription_state=" + subscription_state
    return this.$axios.get(url, {headers: state.headers })
  },

  verifySubscriptionAllowance({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/can-subscribe/"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  getSubscriptionInvoices({state}, [offset]){
    const url = process.env.MIIO_API_URL + "/v4/subscriptions/invoices?ordering=-date&limit=5&offset=" + offset
    return this.$axios.get(url, {headers: state.headers })
  },

  retrySubscriptionCapture({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/subscriptions/" + id + "/retry-subscription-capture/"
    return this.$axios.post(url, null, { headers: state.headers })
  },

  getFaqList({state}){
    const url = process.env.MIIO_SERVICES_URL + "/v2/faq-search?limit=25"
    return this.$axios.get(url, {headers: state.headers })
  },

  // SETTINGS
  getListOfGuests({state}, [offset, search]){
    let url = process.env.MIIO_API_URL + "/v4/wallet/" + state.wallet_id + "/guests?limit=10&offset=" + offset
    if(search){
      url += "&search=" + search
    }
    return this.$axios.get(url, {headers: state.headers })
  },

  getListOfPermissionGroups({state}){
    const url = process.env.MIIO_API_URL + "/v4/permissions/groups?product=FLEETS_DASHBOARD"
    return this.$axios.get(url, {headers: state.headers })
  },

  getWalletGuest({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/guests/" + id
    return this.$axios.get(url, {headers: state.headers })
  },

  deteleWalletGuest({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/guests/" + id
    return this.$axios.delete(url, {headers: state.headers })
  },

  sendGuestInvitation({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/guests/send-invitation"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  acceptGuestInvitation({state}, [id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/guests/" + id + "/invitation"
    return this.$axios.put(url, null, {headers: state.headers })
  },

  // GUESTS
  createGuestsPermissions({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/manage-permissions"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  removeGuestsPermissions({state}, [body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + state.wallet_id + "/manage-permissions"
    return this.$axios.delete(url, body, {headers: state.headers })
  },

  // PASS WALLET AS PROP
  getDetailsFromWallet({state}, [wallet_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet_id
    return this.$axios.get(url, {headers: state.headers })
  },

  addFundsToSelectedWallet({state}, [body, wallet]){
    const url = process.env.MIIO_SERVICES_URL + `/v3/wallet/${wallet}/add-funds`
    return this.$axios.post(url, body, {headers: state.headers })
  },

  updateCustomWalletDetails({state}, [wallet, body]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet
    return this.$axios.put(url, body, {headers: state.headers })
  },

  getDriverCardsFromCustomWallet({state}, [wallet]){
    let url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet + "/cards/"
    return this.$axios.get(url, {headers: state.headers })
  },

  removeGuestFromCustomWallet({state}, [id, wallet_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet_id + "/guests/" + id
    return this.$axios.delete(url, {headers: state.headers })
  },

  sendDriverInviteWithCustomWallet({state}, [body, wallet_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet_id + "/members/send-invitation"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  inviteGuestWithCustomWallet({state}, [body, wallet_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet_id + "/guests/send-invitation"
    return this.$axios.post(url, body, {headers: state.headers })
  },


  removeGuestsPermissionsWithCustomWallet({state}, [guest_id, permission_id, wallet_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet_id + "/manage-permissions"
    return this.$axios.delete(url, { headers: state.headers, data: { guests: [guest_id], permission_groups: [permission_id] }})
  },

  updateGuestsPermissionsWithCustomWallet({state}, [body, wallet_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet_id + "/manage-permissions"
    return this.$axios.post(url, body, {headers: state.headers })
  },

  deleteWalletGuestWithCustomWallet({state}, [type, guest_id, wallet_id]){
    const url = process.env.MIIO_SERVICES_URL + "/v3/user/wallet/" + wallet_id + "/" + type + "/" + guest_id
    return this.$axios.delete(url, {headers: state.headers })
  },


  // NO WALLET
  getListOfAllMembersAndGuests({state}, [type, guestState]){
    const url = process.env.MIIO_API_URL + "/v4/wallet/" + type + "?state=" + guestState
    return this.$axios.get(url, { headers: state.headers })
  },

  // V2
  changeCardCredit({state}, [payload]){
    const url = `${process.env.MIIO_SERVICES_URL}/v3/user/wallet/${state.wallet_id}/cards/adjust-limit/`
    return this.$axios.put(url, payload, { headers: state.headers })
  },

  changeCreditRenovation({state}, [payload]){
    const url = `${process.env.MIIO_SERVICES_URL}/v3/user/wallet/${state.wallet_id}/cards/configure-plafonds/`
    return this.$axios.put(url, payload, { headers: state.headers })
  },
}
export const mutations = {
  SET_USER(state, user){
    state.user = user
  },
  SET_USER_TOKEN(state, token){
    state.userToken = token
    state.headers["X-TOKEN"] = token
  },
  SET_WALLET_ID(state, id){
    state.wallet_id = id
  },
  CLEAR_USER_DATA(state){
    state.user = null
    state.userToken = null
    state.wallet_id = null
    state.GLOBAL.user = null
    state.GLOBAL.wallet = null
    state.GLOBAL.wallet_overview = null
    state.GLOBAL.subscription = null
    state.headers["X-TOKEN"] = null
  },
  SET_GLOBAL_USER_DATA(state, user_data){
    state.GLOBAL.user = user_data
  },
  SET_GLOBAL_WALLET_DETAILS(state, wallet_details){
    state.GLOBAL.wallet = wallet_details
  },
  SET_GLOBAL_WALLET_OVERVIEW(state, wallet_overview){
    state.GLOBAL.wallet_overview = wallet_overview
  },
  SET_GLOBAL_SUBSCRIPTION(state, subscription){
    state.GLOBAL.subscription = subscription
  },
  SET_GUEST_INVITE_ID(state, guest_id){
    state.guest_invite = guest_id
  },
  SET_PAGE_REDIRECT(state, page){
    state.redirect = page
  },
  SET_ALERT(state, alert){
    state.alert = alert
  }
}
export const getters = {
  activeSubscription: state => state.GLOBAL.subscription,
  suspendedSubscriptionDate(state){
    if(state.GLOBAL.subscription && state.GLOBAL.subscription.state === "SUSPENDED"){
      return moment(state.GLOBAL.subscription.valid_until).add(5, "days").format("DD/MM/YYYY")
    }
  },
  verifySuspendedSubscription(state){
    if(state.GLOBAL.subscription){
      return state.GLOBAL.subscription.state === "SUSPENDED"
    }
  },
  verifyEndingSubscription(state){
    if(state.GLOBAL.subscription){
      const notFreePlan = state.GLOBAL.subscription.subscription_plan.name !== "FREE"
      let isEnding = moment(state.GLOBAL.subscription.valid_until).isBetween(moment().subtract(1, "days"), moment().add(5, "days"))
      return notFreePlan && isEnding
    }
  },
  walletDetails(state){
    return state.GLOBAL.wallet
  },
  userDetails(state){
    return state.GLOBAL.user
  },
  walletOverview(state){
    return state.GLOBAL.wallet_overview
  },
  guestInviteId(state){
    return state.guest_invite
  },
  currentUser(state){
    return state.user
  },
  walletBalance(state){
    return parseFloat(state.GLOBAL.wallet.balance)
  },
  walletPaymentLogic(state){
    if(state.GLOBAL.wallet){
      return state.GLOBAL.wallet.payment_logic
    }
  },
  defaultPaymentMethodDetails(state){
    return state.GLOBAL.wallet.payment_method_info
  },
  checkActiveAutoRecharge(state){
    return state.GLOBAL.wallet.has_auto_recharge
  },
  userPermissions(state){
    if(state.GLOBAL.wallet){
      const walletPermissions = state.GLOBAL.wallet.permission_groups
      if(walletPermissions){
        return walletPermissions[0]
      }
    }
  },
  getRoutes(state){
    return state.routes
  },
  blockedCardStates(){
    return ["MISSING_CHARGING_PAYMENT", "MISSING_VALID_PAYMENT_METHOD", "USER_CARD_INACTIVE", "ZERO_BALANCE", "PLAFOND_REACHED"]
  }
}
