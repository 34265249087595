
import UpdateGlobalData from "@/functions/UpdateGlobalData"
 export default {
   name: "ChangeLanguage",
   mixins: [UpdateGlobalData],
   data(){
     return {
       languageMenu: false,
       showAlert: false
     }
   },
   watch: {
     userLanguage(locale){
       if(!this.isLoginPage){
         this.$i18n.setLocale(locale)
       }
     },
     showAlert(value){
       if (value){
         setTimeout(() => {
           this.showAlert = false
         }, 5000)
       }
     }
   },
   async mounted(){
    if (this.userLanguage){
      await this.$i18n.setLocale(this.userLanguage)
    }
   },
   methods: {
     async changeLanguage(locale){
       this.languageMenu = false
       await this.$i18n.setLocale(locale)
       if(!this.isLoginPage){
         try {
           await this.$store.dispatch('updateUserDetails', [{ language: locale }])
           this.showAlert = true
           await this.updateGlobalUserDetails()
           await this.updateUserSubscription()
         } catch (e){
           await this.throw_error(e)
         }
       }
     }
   },
   computed: {
     userLanguage(){
        return this.$store.getters.userDetails ? this.$store.getters.userDetails.language : null
     },
     availableLocales(){
       return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
     },
     isLoginPage(){
       return this.$route.path.includes("login")
     }
   }
 }
