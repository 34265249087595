
export default {
  name: "NewModal",
  props: {
    size: {
      type: Number,
      required: false,
      default: 5
    }
  },
  methods: {
    close() {
      this.$emit("close")
    }
  }
}
