
export default {
  props: {
    routes: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectedRoute(route){
      return this.$route.path.includes(route.name)
    }
  },
  computed: {
    allowRoutes(){
      return !this.$route.path.includes("index") && !this.$route.path.includes("subscription") && !this.$route.path.includes("faq")
    }
  }
}
