
import MainAlert from "@/components/utils/alerts/MainAlert"
import ChangeLanguage from "@/components/v2/forms/ChangeLanguage.vue"
import { mapGetters } from "vuex"
export default {
  components: { ChangeLanguage, MainAlert },
  props: {
    routes: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters(["userDetails"]),
    isIndex() {
      return !this.$route.path.includes('index')
    }
  },
}
