import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
import Cookies from 'js-cookie'
Vue.mixin({
  data(){
    return{
      alert: null
    }
  },
  watch: {
    globalWalletDetails(){
      let permissions = 0
      if(this.globalWalletDetails.permission_groups){
        permissions = this.globalWalletDetails.permission_groups[0].id
      }
      Cookies.set('ZGCmCKTJLQpGtmUD', permissions)
    }
  },
  methods: {
    async throw_error(error){
      let dev = process.env.dev
      if(dev){
        console.log(error)
      } else {
        if(error.message == 'Request failed with status code 401'){
          window.location.reload(true)
        } else {
          this.$sentry.captureException(new Error(error))
        }
      }
    },
    setFirebaseEvent(event, options){
      const userUid = this.$store.state.user ? this.$store.state.user.uid : 'unknown'
      options ?  this.$gtm.push({ event, userUid, options }) : this.$gtm.push({ event, userUid })
    },
    getPhotoUrl(url){
      if(url){
        return url
      } else {
        return '/user_placeholder.svg'
      }
    },
    getEnergyCycle(cycle){
      if(cycle === 'WEEKLY'){
        return this.$t('biHourlyWeekly')
      } else if(cycle === 'DAILY'){
        return this.$t('biHourlyDaily')
      } else {
        return cycle
      }
    },
    async changeRoute(route, params, query){
      await this.$router.push({ name: `${route}___${this.$i18n.locale}`, params: params, query: query})
    },
    async updateGlobalWalletDetails(){
      let wallet_details = await this.$store.dispatch('getWalletDetails')
      this.$store.commit('SET_GLOBAL_WALLET_DETAILS', wallet_details.data)
    }
  },
  computed: {
    wallet_id(){
      return this.$store.state.wallet_id
    },
    globalUserDetails(){
      if(this.$store){
        return this.$store.state.GLOBAL.user
      }
    },
    globalWalletDetails(){
      if(this.$store){
        return this.$store.state.GLOBAL.wallet
      }
    },
    globalPermissions(){
      return Cookies.get('ZGCmCKTJLQpGtmUD')
    }
  },
})
