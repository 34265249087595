
import NewModal from "@/components/v2/modal/NewModal.vue"
export default {
  components: { NewModal },
  methods: {
    closeModal(){
      this.$emit('closeModal')
    }
  }
}
