import jwtDecode from 'jwt-decode'
var cookieparser = require('cookieparser')
export function getUserFromCookie(req) {
  if (process.server && process.static) return
  if (!req.headers.cookie) return
  if (req.headers.cookie) {
    const parsed = cookieparser.parse(req.headers.cookie)
    const accessTokenCookie = parsed.Fgv2MJp5P5ukK4Vu
    if (!accessTokenCookie) return
    try {
      const decodedToken = jwtDecode(accessTokenCookie)
      if (!decodedToken) return
      return decodedToken
    } catch (_) {}
  }
}
