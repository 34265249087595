
import Cookies from 'js-cookie'
export default {
  name: "MainButton",
  data(){
    return {
      permissions: Cookies.get('ZGCmCKTJLQpGtmUD')
    }
  },
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    allow: {
      type: Boolean,
      default: false,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      required: false
    }
  },
}
